import React, {useState} from "react";
import gambleaware from "../assets/footer-assets/gambleaware.png"
import age from "../assets/footer-assets/age.png"
import mail from "../assets/footer-assets/mail.png"
import skype from "../assets/footer-assets/skype.png"
import twitter from "../assets/footer-assets/twitter.png"
import facebook from "../assets/footer-assets/facebook.png"
import logo from "../assets/navbar-assets/logo.svg"
import {Tooltip} from "flowbite-react";
import AuthModalFaq from "./AuthModalFaq";
import AuthModalPrivacy from "./AuthModalPrivacy";
import AuthModalTerms from "./AuthModalTerms";
import Subscription from "./Subscription";
const Footer = () => {

    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenPrivacy, setModalOpenPrivacy] = useState(false);
    const [modalOpenTerms, setModalOpenTerms] = useState(false);

    const openModalTerms = () => {
        setModalOpenTerms(true);
    }

    const closeModalTerms = () => {
        setModalOpenTerms(false);
    }

    const openModalPrivacy = () => {
        setModalOpenPrivacy(true);
    }
    const closeModalPrivacy = () => {
        setModalOpenPrivacy(false);

    }

    const openModal = () => {
        setModalOpen(true);
    };


    const closeModal = () => {
        setModalOpen(false);
    };

    const year = new Date().getFullYear();

    return (
        <div className="flex flex-col max-w-[1450px] mx-auto mt-20 ">
            <div className="border-b border-[#7F87F9] "></div>
            <div className="grid grid-cols-2 md:flex flex-row justify-center gap-x-4 md:gap-x-9 px-3 mt-14 md:mt-20 md:px-20 lg:px-60">

                <div className=" justify-center bg-[#0C142C] flex-1 flex items-center  rounded-lg max-w-[200px] h-14 lg:h-16">
                    <a href="https://www.begambleaware.org/" target="_blank" rel="noreferrer"> <img src={gambleaware} alt="" className=" md:w-fit "/></a>
                </div>
                <div className=" flex  items-center flex-1 justify-center bg-[#0C142C] rounded-lg  md:max-w-[180px] lg:max-w-[200px] ">
                    <img src={age} alt="" className="w-11 md:w-fit"/>
                </div>
            </div>
            <div className="flex flex-col  lg:flex-row mt-10 md:mt-20 md:gap-x-24  mx-auto ">
                <div className="flex flex-row justify-around gap-x-8 md:gap-x-16 lg:gap-x-16 " id="contactUs">
                   <Tooltip content="info@bespokeaffiliates.com"> <a href="mailto:info@bespokeaffiliates.com"><img src={mail} alt=""/></a></Tooltip>
                  <Tooltip content="bespokeaffiliates"> <img src={skype} alt="" className="h"/></Tooltip>
                    <a href="https://twitter.com/bespokeaffi"><img src={twitter} alt=""/></a>
                    <a href="https://www.facebook.com/bespokeaffiliates"><img src={facebook} alt=""/></a>
                </div>
                <Subscription></Subscription>
            </div>
            <div   className="flex flex-row mx-auto gap-x-7 text-sm md:text-base  md:gap-x-32 mt-10 md:mt-20 mb-14 md:mb-20 ">
                <button onClick={openModalTerms}>Terms & Conditions</button>
                {modalOpenTerms && <AuthModalTerms onClose={closeModalTerms} />}
                <button onClick={openModalPrivacy} >Privacy Policy</button>
                {modalOpenPrivacy && <AuthModalPrivacy onClose={closeModalPrivacy} />}
                <button onClick={openModal}>FAQs</button>
                {modalOpen && <AuthModalFaq onClose={closeModal} />}
            </div>
            <div className="border-b border-[#7F87F9] "></div>
            <div  className="flex flex-col md:flex-row  justify-between items-center mb-2 mt-5 text-center md:px-5 xl:px-0">
                <img src={logo} alt="" className="h-[32px]"/>
                <p className="mt-3 md:mt-0">Bespoke Affiliates © - {year} all rights reserved</p>
            </div>
        </div>
    )
}

export default Footer;