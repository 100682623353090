import React from "react";


const HeroSection = () => {
    return (
        <div className="w-full grid  lg:h-[500px] h-[450px] md:h-[300px] lg:grid-cols-7  xl:justify-items-end  bg-hero-mobile   md:bg-hero bg-cover bg-bottom md:bg-center lg:bg-right bg-no-repeat">
            <div className="max-w-[1450px] lg:col-start-2 md:p-10 px-3 pt-12 md:pt-10 lg:p-0 lg:col-span-3 flex flex-col items-start justify-start lg:justify-center  ">
                <h1 className="font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl lg:leading-[55px] xl:leading-[55px]  ">
                    <span className="text-[#7F87F9]">Get access</span> to the most <br/> competitive and
                    affiliate-oriented <br/> brands in <span className="text-[#7F87F9]">iGaming
                    industry</span>.
                </h1>
                <a href="https://admin.bespokeaffiliates.com/partner/register"
                   className="bg-gradient-to-r from-indigo-900 to-indigo-400 rounded-3xl px-6  py-3 font-bold mt-10 ">JOIN
                    NOW</a>
            </div>
        </div>
    )
}

export default HeroSection;