import React from "react";
import coin from "../assets/Section4/coin.png"
import pie  from "../assets/Section4/pie.png"
import diamond from "../assets/Section4/diamond.png"
import mail from "../assets/Section4/mail.png"
const Section4 = () => {
    return (
        <div className="w-full flex flex-col mt-10 bg-blury lg:bg-center bg-no-repeat bg-cover  relative z-20 ">
            <div className="max-w-[1450px] mx-auto flex flex-col items-center justify-center text-center mb-20 " id="whyUs">
                <h2 className="text-4xl font-bold mt-16">WHY US?</h2>
                <div className="flex flex-col items-center md:items-baseline md:grid md:grid-cols-2 lg:flex items- gap-14  lg:flex-row  mt-24">
                    <div className="flex flex-col flex-1 items-center ">
                        <img src={coin} alt="" className="w-[150px]"/>
                        <h2 className="font-bold text-xl mt-9">Loyal Players</h2>
                        <p className="mt-2">High player value through
                            great CRM.</p>
                    </div>
                    {/*<div className="flex flex-col flex-1 items-center ">*/}
                    {/*    <img src={pie} alt="" className="w-[150px]"/>*/}
                    {/*    <h1 className="font-bold text-xl mt-9">High Commission Rates</h1>*/}
                    {/*    <p className="mt-2">Earn Up to 50% Commission*/}
                    {/*        For Your Referrals.</p>*/}
                    {/*</div>*/}
                    <div className="flex flex-col flex-1 items-center">
                        <img src={diamond} alt="" className="w-[150px]"/>
                        <h2 className="font-bold text-xl mt-9">High Quality Marketing</h2>
                        <p className="mt-2">Powerful And Innovative Marketing Tools.</p>
                    </div>
                    <div className="flex flex-col  flex-1 items-center">
                        <img src={mail} alt="" className="w-[150px]"/>
                        <h2 className="font-bold text-xl mt-9">Manager Support</h2>
                        <p className="mt-2">A Personal Affiliate Manager Assigned To Each Affiliate.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section4;