import React from "react";
import handshake from "../assets/Section3/handshake.png"
import approve from "../assets/Section3/approve.png"
import earn from "../assets/Section3/earn.png"
import arrow from "../assets/Section3/arrow.png"
import arrow2 from "../assets/Section3/arrow2.png"
import vector from "../assets/Section3/Vector.png"
import vector2 from "../assets/Section3/Vector(1).png"
const ThirdSection = () => {
    return (
        <div className="w-full relative mt-10 md:mt-0" id="about"   >
            <div className="max-w-[1450px] flex flex-col justify-around  mx-auto  relative  items-center px-3 md:p-10 gap-10 z-20  " >
                <div className="text-center my-5 " >
                    <p className="uppercase  text-2xl md:text-4xl font-bold " >Are you ready to take your
                    </p>
                    <p className="uppercase  text-2xl md:text-4xl font-bold mb-5 ">
                        affiliate business to new heights?</p>
                    <p>
                        Look no further than Bespoke Affiliates, where we deliver a first-class experience tailored to your
                        needs. We cater to affiliates who thrive
                    </p>
                    <p> on high-stakes thrills and demand lightning-fast payments.</p>
                </div>
                <div className="flex  flex-col lg:flex-row  ">
                    <div className="flex flex-1 flex-col items-center  text-center">
                        <img src={handshake} alt="" className="max-w-[128px] "/>

                        <div className="flex flex-row items-center gap-2 mt-3">
                            <div className="w-[31px] h-[31px] bg-gradient-to-r from-indigo-900 to-indigo-400 rounded-full font-bold flex justify-center items-center  text-xl"><p>1</p></div>
                            <h2 className="font-bold">JOIN</h2>
                        </div>
                        <p className="mt-2 md:px-20 lg:px-0 ">Take the first step towards lucrative affiliate earnings by registering for the Bespoke Affiliate
                            Program today. It's quick, easy, and free to join!
                        </p>
                    </div>
                    <div className="flex-1 mt-10 hidden lg:block">
                            <img src={arrow2} alt="" className=""/>
                    </div>
                    <div className=" flex lg:hidden flex-1 mx-auto mt-10 md:my-10  ">
                        <img src={arrow} alt="" className="h-28"/>
                    </div>
                    <div className="flex flex-1 flex-col items-center  text-center mt-10 md:mt-0">
                        <img src={approve} alt="" className="max-w-[128px] "/>
                        <div className="flex flex-row items-center gap-2 mt-3">
                            <div className="w-[31px] h-[31px] bg-gradient-to-r from-indigo-900 to-indigo-400 rounded-full font-bold flex justify-center items-center  text-xl"><p>2</p></div>
                            <h2 className="font-bold">APPROVAL</h2>
                        </div>
                        <p className="mt-2  md:px-20 lg:px-0">Once you've submitted your application, our team will carefully review it. Upon approval, you'll
                            gain access to a world of rewarding opportunities and start reaping the benefits of our
                            partnership.
                        </p>
                    </div>
                    <div className="flex-1 mt-10 hidden lg:block">
                        <img src={arrow2} alt="" className=""/>
                    </div>
                    <div className="flex lg:hidden flex-1  mx-auto mt-10  md:my-10 ">
                        <img src={arrow} alt="" className="h-28"/>
                    </div>
                    <div className="flex flex-1 flex-col items-center text-center mt-10 md:mt-0">
                        <img src={earn} alt="" className="max-w-[128px] "/>
                        <div className="flex flex-row items-center gap-2 mt-3">
                            <div className="w-[31px] h-[31px] bg-gradient-to-r from-indigo-900 to-indigo-400 rounded-full font-bold flex justify-center items-center  text-xl"><p>3</p></div>
                            <h2 className="font-bold">EARN COMMISSIONS</h2>
                        </div>
                        <p className="mt-2  md:px-20 lg:px-0">As an affiliate, your success lies in the commissions you earn. When you refer customers to our
                            trusted iGaming brands, you'll receive generous commissions on their activities.
                        </p>
                    </div>
                </div>
                <a href="https://admin.bespokeaffiliates.com/partner/register" className="font-bold bg-gradient-to-r from-[#211D8A] to-indigo-400 rounded-3xl px-14 py-2 mt-6" >JOIN NOW</a>
            </div>
            <img src={vector} alt="" className=" absolute top-0 left-0 hidden lg:inline"/>
            <img src={vector2} alt="" className="absolute top-[400px] z-10  hidden lg:inline  "/>
        </div>
    )
}

export default ThirdSection;