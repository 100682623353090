
import PrivacyPolicy from "./PrivacyPolicy";
import React, {useEffect} from "react";
import {TfiClose} from "react-icons/tfi";

const AuthModalPrivacy = ({onClose}) => {

    const handleClickOutsidePrivacy = (e) => {
        if (e.target.classList.contains('overlay')) {
            onClose();
        }
    };


    const handleKeyDownPrivacy = (e) => {
        if (e.key === 'Escape') {
            onClose();
        }
    }
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDownPrivacy);

        return () => {
            window.removeEventListener('keydown', handleKeyDownPrivacy);
        };
    }, );



    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center mt-20 md:mt-0 ">
            <div className="overlay absolute inset-0 bg-black bg-opacity-60" onClick={handleClickOutsidePrivacy}></div>
            <div className="z-10 p-6  rounded shadow-md max-w-fit  overflow-hidden  ">

                <div className="max-h-[800px] overflow-y-auto relative mt-10  ">
                    <button  className="absolute right-5 top-4 md:right-9 lg:right-12 lg:top-8" onClick={onClose}>
                        <TfiClose className=""/>
                    </button>
                    <PrivacyPolicy/>

                </div>

            </div>
        </div>

    )
}

export default AuthModalPrivacy;