import React from "react";

const TermsAndConditions = () => {
    return (
        <div className="max-w-[1450px] mx-auto bg-[#111C2D]  flex flex-col  py-10   px-3 md:px-10 shadow-lg ">
            <div>
                <div className="text-center text-3xl font-bold mb-20">
                    <h2>Terms and Conditions</h2>
                    <h2>Version 1. effective from 26.03.2024 and last updated 26.03.2024.</h2>
                </div>
                <p>This is an agreement (“The Affiliate Agreement”) between you (“you” or “Affiliate”) and
                    www.bespokeaffiliates.com by GT Holding B.V., a company with registration number 156734 and
                    registered address at Johan Van Walbeeckplein 24, Willemstad, Curaçao (“Company”, “us”, “we” or
                    "Affiliate Program").

                    By registering for the Affiliate Program, and by accessing and using any of our marketing tools or
                    accepting any reward, bonus or commission, whether contained in the Affiliate Agreement or elsewhere
                    as a part of our Affiliate Program, you will be deemed to have read, understood and agreed to the
                    Affiliate Agreement.

                    We may periodically make modifications to this Agreement. While we will do our best to notify you of
                    such changes, we recommend that you revisit this page regularly. Your continued use of the Affiliate
                    Program will constitute your consent to the updated Agreement.
                </p>
            </div>
            <div className="my-10">
                <h5 className="font-bold text-2xl mb-10 text-indigo-400">Definitions</h5>
                <ul className="">
                    <li><b className="text-indigo-400">1.1 “Affiliate” </b> means you, the person or entity, who applies
                        to participate in the Affiliate Program.
                    </li>
                    <li><b className="text-indigo-400">1.2 “Affiliate Account”</b>  means the account of the Affiliate
                        set up after an Affiliate Application is made by the Affiliate to take part in the Affiliate
                        Program and approved by Company.
                    </li>
                    <li><b className="text-indigo-400">1.3 “Affiliate Agreement”</b>  means (i) all the terms and
                        conditions set out in this document, (ii) the terms and conditions of the Commission Structures
                        applicable to the different products and brands, and (iii) any other rules or guidelines of the
                        Company and/or Websites made known to the Affiliate from time to time.
                    </li>
                    <li><b className="text-indigo-400">1.4 “Affiliate Application”</b>  means the application made by the
                        Affiliate to participate in the Affiliate Program.
                    </li>
                    <li><b className="text-indigo-400">1.5 “Affiliate Links”</b>  means internet hyperlinks used by the
                        Affiliate to link from the Affiliate Website(s) or any other any third party website to Company
                        Websites.
                    </li>
                    <li><b className="text-indigo-400">1.6 “Affiliate Program”</b>  means collaboration between the
                        Company and the Affiliate whereby the Affiliate promotes the Company’s websites and creates
                        Affiliate Links from the Affiliate Website(s) to Company’s websites. For such services the
                        Affiliate is paid a commission depending on the generated traffic to the Company’s websites,
                        subject to terms within this Affiliate Agreement and to the applicable product-specific
                        Commission Structure.
                    </li>
                    <li><b className="text-indigo-400">1.7 “Affiliate Wallet”</b>  means an online wallet in the name of
                        the Affiliate into which Company pays the commission and any other payments due to the
                        Affiliate, which the Affiliate can withdraw in accordance with the Affiliate Agreement;
                    </li>
                    <li><b className="text-indigo-400">1.8 “Affiliate Website”</b>  means any website which is
                        maintained, operated or otherwise controlled by the Affiliate.
                    </li>
                    <li><b className="text-indigo-400">1.9  “Company”</b> shall mean GT Holding B.V. and any other
                        company within our group, including our parent companies, their parent companies and all of the
                        subsidiaries of these respective companies.
                    </li>
                    <li><b className="text-indigo-400">1.10 “Company Websites”</b>  means the website
                        www.bespokeaffiliates.com or other such websites (including mirror websites) as may be added to
                        this Affiliate Program from time to time, operated by GT Holding B.V.
                    </li>
                    <li><b className="text-indigo-400">1.11 “Commission”</b>  means the percentage of the Net Gaming
                        Revenue, or, where applicable, a fixed amount for a New Customer (CPA structure) as set out in
                        the Commission Structures.
                    </li>
                    <li><b className="text-indigo-400">1.12 “Commission Structures”</b>  means any specific reward
                        structures expressly agreed between Company and the Affiliate.
                    </li>
                    <li><b className="text-indigo-400">1.13 “Confidential Information”</b>  means any information of
                        commercial or essential value relating to Company such as, but not limited to, financial
                        reports, trade secrets, know-how, prices and custom quotes, business information, products,
                        strategies, databases, technology, information about New Customers, other customers and users of
                        Company Websites, marketing plans and manners of operation.
                    </li>
                    <li><b className="text-indigo-400">1.14 “Intellectual Property Rights”</b>  means any copyrights,
                        trademarks, service marks, domain names, brands, business names, and registrations of the
                        aforesaid and/or any other similar rights of this nature.
                    </li>
                    <li><b className="text-indigo-400">1.15 “Net Gaming Revenue” or “NGR”</b>  means all monies received
                        by Company from New Customers as placed bets, less (a) winnings returned to New Customers, (b)
                        issued bonuses, (c) net balance corrections, (d) administration fees, (e) fraud costs and
                        chargebacks. For the avoidance of doubt, all Net Gaming Revenue amounts mentioned above are only
                        related to New Customers referred to Company Websites by the Affiliate Website(s).
                    </li>
                    <li><b className="text-indigo-400">1.16 “New Customer”</b>  means a new, first-time customer of the
                        Company who made a first deposit amounting to at least the applicable minimum deposit at Company
                        Websites’ player account, in accordance with the applicable terms and conditions of Company
                        Websites’. This excludes the Affiliate, its employees, relatives and friends.
                    </li>
                    <li><b className="text-indigo-400">1.17 “Parties”</b>  means Company and the Affiliate (each a
                        “Party”).
                    </li>
                    <li><b className="text-indigo-400">1.18 “Personal Data”</b>  means any information relating to any
                        person, whether individual or legal that is or may be identified, directly or indirectly.
                    </li>
                </ul>

            </div>
            <div>
                <h5 className="text-2xl font-bold mb-10 text-indigo-400">Affiliate Obligations</h5>
                <ul className="">
                    <li><b className="text-indigo-400">2.1 Registering as Affiliate</b> To become a member of our
                        Affiliate Program you must accept these terms and conditions by ticking the respective box while
                        submitting the Affiliate Application. The Affiliate Application will form an integral part of
                        the Affiliate Agreement.

                        We will, at our sole discretion, determine whether or not to accept an Affiliate Application and
                        our decision is final and not subject to any right of appeal. We will notify you by email as to
                        whether or not your Affiliate Application has been successful.

                        You will provide any documentation required by Company to verify the Affiliate Application and
                        to verify the Affiliate Account information at any time during the term of the Affiliate
                        Agreement. This documentation may include but is not limited to: bank statements, individual or
                        corporate identity papers and proof of address.

                        It is your sole obligation to ensure that any information you provide us with when registering
                        with the Affiliate Program is correct and that such information is kept up-to-date at all times.
                    </li>
                    <li><b className="text-indigo-400">2.2 Affiliate login details</b> It is your sole obligation and
                        responsibility to ensure that login details for your Affiliate Account are kept confidential and
                        secure at all times.

                        Any unauthorised use of your Affiliate Account resulting from your failure to adequately guard
                        your login information shall be your sole responsibility, and you remain solely responsible and
                        liable for all such activity occurring under your Affiliate Account user ID and password
                        (whether such activity was undertaken by you or not). It is your obligation to inform us
                        immediately if you suspect illegal or unauthorised use of your Affiliate Account.
                    </li>
                    <li><b className="text-indigo-400">2.3 Affiliate Program participation</b> The Affiliate Program is
                        intended for your direct participation. Opening an Affiliate Account for a third party,
                        brokering or transfering an Affiliate Account is not accepted. Affiliates wishing to transfer an
                        account to another beneficial owner must contact us and request permission. Besides, you shall
                        not open more than one Affiliate Account without our prior written consent.

                        By agreeing to participate in the Affiliate Program, you agree to use your best efforts to
                        actively advertise, market and promote the Company Websites in accordance with the Affiliate
                        Agreement and Company’s instructions from time to time. You will ensure that all activities
                        taken by you under the Affiliate Agreement will be in Company’s best interest and will in no way
                        harm the Company’s reputation or goodwill.

                        You may link to the Company Website’s using the Affiliate Links or other such materials as we
                        may from time to time approve. This is the only method by which you may advertise on our behalf.
                    </li>
                    <li><b className="text-indigo-400">2.4 Affiliate Website</b> You will be solely responsible for the
                        development, operation and maintenance of the Affiliate Website and for all materials that
                        appear on the Affiliate Website. You shall at all times ensure that the Affiliate Website is
                        compliant with all applicable laws, including General Data Protection Regulation (GDPR), and
                        functions as a professional website.

                        You will not present the Affiliate Website in such a way so that it may cause confusion with the
                        Company Websites, or so that it may give the impression that it is owned or operated by Company.

                        The Affiliate Website will not contain any defamatory, libellous, discriminatory or otherwise
                        unsuitable content (including, but not limited to, violent, obscene, derogatory or pornographic
                        materials or content which would be unlawful in target country).
                    </li>
                    <li><b className="text-indigo-400">2.5 Valid traffic and good faith</b> You will not generate
                        traffic to Company Websites by registering as a New Customer whether directly or indirectly (for
                        example by using associates, family members or other third parties). Such behaviour shall be
                        deemed as fraud.

                        You will also not attempt to benefit from traffic not generated in good faith. If you have any
                        reasonable suspicion that any New Customer referred by you is in any way associated to bonus
                        abuse, money laundering, fraud, or other abuse of remote gaming websites, you will immediately
                        notify us of this.

                        You hereby recognise that any New Customer found to be a bonus abuser, money launderer or
                        fraudster or who assists in any form of affiliate fraud (whether notified by you or later
                        discovered by us) does not count as a valid New Customer under the Affiliate Agreement, and
                        thereby no Commission shall be payable in relation to such New Customers.
                    </li>
                    <li><b className="text-indigo-400">2.6 Unsuitable websites</b>You will not use any Affiliate Links
                        or otherwise place any digital advertisements whatsoever featuring our Intellectual Property on
                        any unsuitable websites (whether owned by a third party or otherwise).

                        Unsuitable websites include, but are not limited to, websites that are aimed at children,
                        display illegal pornography or other illegal sexual acts, promote violence, promote
                        discrimination based on race, sex, religion, nationality, disability, sexual orientation, or
                        age, promote illegal activities or in any way violate the intellectual property rights of any
                        third party or of the Company, or breach any relevant advertising regulations or codes of
                        practice in any territory where such Affiliate Links or digital advertisements may be featured.

                    </li>
                    <li><b className="text-indigo-400">2.7 Affiliate Links</b>The Affiliate Links shall be displayed at
                        least as prominently as any other sales link on the Affiliate Website.

                        You will only use Affiliate Links provided by Company within the scope of the Affiliate Program.
                        Masking your Affiliate Links (for example hiding the source of the traffic sent to Company’s
                        Websites) is also prohibited.
                    </li>
                    <li><b className="text-indigo-400">2.8 Email and SMS marketing</b> If sending any emails or SMS
                        communications to individuals which (i) include any of Company’s Intellectual Property Rights;
                        or (ii) otherwise intend to promote Company Websites, you must first obtain our permission to
                        send such emails.

                        If such permission is granted by the Company, you must then ensure you have each and every
                        recipient’s explicit consent to receive marketing communications in the form of communication to
                        be sent (i.e. by SMS or email) and that such individuals have not opted out of receiving such
                        communication. You must also make it clear to the recipient that all marketing communications
                        are sent from you and are not from our Company.
                    </li>
                    <li><b className="text-indigo-400">2.9 Use of Company Intellectual Property Rights</b>Any use of
                        Company’s Intellectual Property Rights must be in accordance with any brand guidelines issued to
                        you from time to time and are always subject to the approval required in clause below.

                        You will not register domain names, as well as search terms or other identifiers for use in any
                        search engine, portal, app store, sponsored advertising service or other referral service which
                        are identical to any of the Company’s trademarks or otherwise include the Company trademarks.
                    </li>
                    <li><b className="text-indigo-400">2.10 Approved creative</b> You will not use any advertising
                        layout or creative (including banners, images, logos) incorporating our Intellectual Property
                        Rights unless the advertising layout or creative was provided to you by the Company or (if
                        advertising layouts are created by you) without the advance written approval of Company. You
                        will not modify the appearance of any advertising that has been provided to you or for which
                        approval was granted.

                        It is your responsibility to seek approval from Company in time for launch of any advertising
                        campaign or creative, to ensure you have written approval from the Company in relation to
                        advertising, and to be able to evidence such approval upon request.
                    </li>
                    <li><b className="text-indigo-400">2.11 Loyalty Programs</b> You will not offer any cash-back /
                        value-back or similar programs, other than such programs as are offered on the Company Websites.
                    </li>
                    <li><b className="text-indigo-400">2.12 Responsible Gaming</b> The Company has ongoing commitment to
                        responsible gaming and prevention of gambling addiction. You agree to actively cooperate with
                        the Company to convey a responsible gaming message. Specifically, you will not use any material
                        or in any way target persons who are under 18 or the legal gambling age in their jurisdiction.

                    </li>
                    <li><b className="text-indigo-400">2.13 Illegal activity</b>You will not target any territory or
                        jurisdictions where gambling is illegal. You will act within the relevant and / or applicable
                        law at all times and you will not perform any act which is illegal in relation to the Affiliate
                        Program or otherwise.
                    </li>
                    <li><b className="text-indigo-400">2.14 Data Protection and Cookies</b> You shall at all times
                        comply with the General Data Protection Regulation (GDPR) and any existing or new data
                        protection acts, regulations or law applicable to your territory. This includes all applicable
                        legislation and/or regulations relating to the use of ‘cookies’.

                    </li>
                    <li><b className="text-indigo-400">2.15 Cost and expense</b> You shall be solely responsible for all
                        risk, costs and expenses incurred by you in meeting your obligations under the Affiliate
                        Agreement.
                    </li>
                    <li><b className="text-indigo-400">2.16 Company monitoring of Affiliate activity</b> You will
                        immediately give Company all such assistance as is required and provide us with all such
                        information as is requested by Company to monitor your activity under the Affiliate Program.
                    </li>
                    <li><b className="text-indigo-400">2.17 Commissions paid incorrectly</b> The Affiliate agrees to
                        immediately upon request by Company, return all Commissions received based on New Customers
                        referred to Company in breach of the Affiliate Agreement or relating to fraudulent or falsified
                        transactions.
                    </li>
                </ul>

            </div>
            <div>
                <h5 className="font-bold text-2xl my-10 text-indigo-400">Affiliate Rights</h5>
                <ul className="">
                    <li><b className="text-indigo-400">3.1. Right to direct New Customers</b> We grant you the
                        non-exclusive, non-assignable, right, during the term of this Affiliate Agreement, to direct New
                        Customers to such Company Websites as we have agreed with you in strict accordance with the
                        terms of the Affiliate Agreement. You shall have no claim to Commission or other compensation on
                        business secured by persons or entities other than you.
                    </li>
                    <li><b className="text-indigo-400">3.2. Licence to use Company Intellectual Property Rights</b> We
                        grant to you a non-exclusive, non-transferable licence, during the term of this Affiliate
                        Agreement, to use the Company Intellectual Property Rights, which we may from time to time
                        approve solely in connection with the display of the promotional materials on the Affiliate
                        Website or in other such locations as may have been expressly approved (in writing) by Company.
                        This licence cannot be sub-licensed, assigned or otherwise transferred by you.
                    </li>
                    <li><b className="text-indigo-400">3.3. Players’ Personal Data</b> For the purpose of the services
                        delivered under this Agreement, it is understood that the Affiliate shall not have access to any
                        Personal Data of Company’s customers.
                    </li>
                </ul>
            </div>
            <div>
                <h5 className="font-bold text-2xl my-10 text-indigo-400">Company Obligations</h5>
                <ul className="">
                    <li><b className="text-indigo-400">4.1. Provision of Materials and Information</b> We shall use our
                        best
                        efforts to provide you with all materials and information required for necessary implementation
                        of the
                        Affiliate Links.
                    </li>
                    <li><b className="text-indigo-400">4.2. Registration and Tracking of New Customers</b> At our sole
                        discretion,
                        we will register any New Customers directed to the Company Websites by you and we will track
                        their
                        transactions. We reserve the right to refuse New Customers (or to close their accounts) if
                        necessary to
                        comply with any requirements we may periodically establish.
                    </li>
                    <li><b className="text-indigo-400">4.3. Provision of Monitoring Tools</b> We shall make available
                        monitoring
                        tools which enable you to monitor your Affiliate Account and the level of your Commission and
                        the payment
                        thereof.
                    </li>
                    <li><b className="text-indigo-400">4.4. Use and Processing of Personal Data</b> We shall use and
                        process the
                        following personal data of an Affiliate or any Affiliate employee, as follows: your username for
                        logging
                        in, your email address, name, date of birth, your country and address, telephone number and
                        financial data
                        for the purposes of ensuring a high level of security, fulfilling the AML legal requirements and
                        for
                        managing our business relationship.
                    </li>
                    <li><b className="text-indigo-400">4.5. Commission Payment</b> Subject to your strict adherence to
                        the
                        Affiliate Agreement, we shall pay you the Commission in accordance with Clause 6.
                    </li>
                </ul>
            </div>

            <div>
                <h5 className="font-bold text-2xl my-10 text-indigo-400">Company Rights and Remedies</h5>
                <ul className="">
                    <li><b className="text-indigo-400">5.1. Suspension of Participation</b> The right to suspend your
                        participation in the Affiliate Program for the period required to investigate any activities
                        that may be in
                        breach of the Affiliate Agreement. During such period of suspension payment of Commissions will
                        also be
                        suspended.
                    </li>
                    <li><b className="text-indigo-400">5.2. Withholding Commission</b> The right to withhold any
                        Commission or any
                        other payment to the Affiliate arising from or relating to any specific campaign, traffic,
                        content or
                        activity conducted or created by the Affiliate which is in breach of the Affiliate’s obligations
                        under the
                        Affiliate Agreement.
                    </li>
                    <li><b className="text-indigo-400">5.3. Withholding from Commission</b> The right to withhold from
                        the
                        Commission monies which Company deems reasonable to cover any indemnity given by the Affiliate
                        hereunder or
                        to cover any liability of Company which arises as a result of the Affiliate’s breach of the
                        Affiliate
                        Agreement.
                    </li>
                    <li><b className="text-indigo-400">5.4. Termination</b> Immediately terminate the Affiliate
                        Agreement.
                    </li>
                    <li><b className="text-indigo-400">5.5. Withholding Affiliate Wallet</b> The right to withhold
                        monies held in
                        the Affiliate Wallet if they are not withdrawn within a period of 3 (three) months from the date
                        of the
                        termination of the Affiliate Agreement in accordance with clause 9.1.
                    </li>
                </ul>
            </div>

            <div>
                <h5 className="font-bold text-2xl my-10 text-indigo-400">Commission and Payment</h5>
                <ul className="">
                    <li><b className="text-indigo-400">6.1. Earning Commission</b> Subject to your adherence to the
                        provisions of
                        the Affiliate Agreement, you will earn Commission in accordance with the Commission Structure.
                        We retain
                        the right to change the Commission percentage and method of calculation of Commission in
                        accordance with
                        this clause.
                    </li>
                    <li><b className="text-indigo-400">6.2. Monthly Payment</b> The Commission is calculated at the end
                        of each
                        month and payments shall be made on a monthly basis in arrears, not later than the 10th of the
                        following
                        calendar month.
                    </li>
                    <li><b className="text-indigo-400">6.3. Payment Method</b> Payment of Commission will be made
                        through our
                        Affiliate Wallet. Due to existing regulations, Affiliates may be required for verification and
                        “know your
                        customer” documentation before a withdrawal can be accessed.
                    </li>
                    <li><b className="text-indigo-400">6.4. Minimum Withdrawal Amount</b> A minimum amount of $100
                        (hundred US
                        dollars) may be withdrawn from the Affiliate Wallet at one time.
                    </li>
                    <li><b className="text-indigo-400">6.5. Error Correction</b> If an error is made in the calculation
                        of the
                        Commission, the Company has the right to correct such calculation at any time and will
                        immediately pay out
                        underpayment or reclaim overpayment made to the Affiliate.
                    </li>
                    <li><b className="text-indigo-400">6.6. Commission Restructuring</b> The Affiliate may, at the
                        Company’s sole
                        discretion, be provided with the opportunity to restructure its commission structure.
                    </li>
                    <li><b className="text-indigo-400">6.7. Acceptance of Commission Payment</b> The Affiliate’s
                        acceptance of a
                        Commission payment shall constitute the full and final settlement of the balance due for the
                        relevant
                        period.
                    </li>
                    <li><b className="text-indigo-400">6.8. Tax Responsibility</b> The Commission shall be deemed to be
                        exclusive
                        of value added tax or any other applicable tax. The Affiliate shall have the sole responsibility
                        to pay any
                        and all taxes, levies, charges and any other money payable or due to any tax authority,
                        department or other
                        competent entity as a result of the compensation generated under the Affiliate Agreement.
                    </li>
                </ul>
            </div>
            <div>
                <h5 className="font-bold text-2xl my-10 text-indigo-400">Standard Commission Structures</h5>
                <p>
                    At Bespoke Affiliates, we offer commission and bonus structures tailored to suit individual needs
                    and
                    circumstances. These structures are custom-made deals designed to reward your efforts appropriately.
                    The
                    commission rates and bonuses are subject to negotiation and agreement with your designated account
                    manager. No
                    Negative Carryover.
                </p>
            </div>

            <div>
                <h5 className="font-bold text-2xl my-10 text-indigo-400">Confidential Information</h5>
                <p>
                    During the term of the Affiliate Agreement, you may from time to time be entrusted with confidential
                    information relating to our business, operations, or underlying technology and/or the Affiliate
                    Program
                    (including, for example, the Commissions earned by you under the Affiliate Program).
                </p>
                <p>
                    You agree to avoid disclosure or unauthorised use of any such confidential information to third
                    persons or
                    outside parties unless you have our prior written consent. You also agree that you will use the
                    confidential
                    information only for the purposes of the Affiliate Agreement. Your obligations in regards to this
                    clause survive
                    the termination of this Agreement.
                </p>
                <p>
                    In addition, you must not issue any press release or similar communication to the public with
                    respect to your
                    participation in the Affiliate Program without the prior written consent of the Company (with
                    approval of the
                    exact content to also be approved by Company).
                </p>
            </div>

            <div>
                <h5 className="font-bold text-2xl my-10 text-indigo-400">Term and Termination</h5>
                <p>
                    <b className="text-indigo-400">9.1. Term:</b> The term of the Affiliate Agreement will begin when you are approved as an
                    Affiliate and will
                    be continuous unless and until either Party notifies the other in writing that it wishes to
                    terminate the
                    Agreement. In this case, the Agreement will be terminated 30 days after such notice is given. For
                    purposes of
                    notification of termination, delivery via e-mail is considered a written and immediate form of
                    notification.
                </p>
                <p>
                    For the avoidance of doubt, Company may also terminate (in accordance with Clause 5 above) upon
                    immediate notice
                    at any time for the Affiliates failure to meet their obligations under the Agreement or otherwise
                    for the
                    Affiliate’s negligence.
                </p>
                <p>
                    <b className="text-indigo-400">9.2. Affiliate actions upon termination:</b> Upon termination you must immediately remove all of
                    Company
                    banners or creatives from the Affiliate Website and disable all Affiliate Links from the Affiliate
                    Website to
                    all Company Websites.
                </p>
                <p>
                    All rights and licenses given to you in the Affiliate Agreement shall immediately terminate.
                </p>
                <p>
                    You will return to Company any confidential information and all copies of it in your possession and
                    control, and
                    will cease all uses of all Company Intellectual Property Rights.
                </p>
                <p>
                    <b className="text-indigo-400">9.3. Commission:</b> Upon termination of the Affiliate Agreement for any reason, all Commission
                    relating to
                    any New Customers directed to Company during the term shall not be payable to the Affiliate as from
                    the date of
                    termination.
                </p>
            </div>

            <div>
                <h5 className="font-bold text-2xl my-10 text-indigo-400">Miscellaneous</h5>
                <p>
                    <b className="text-indigo-400">10.1. Disclaimer:</b> We make no express or implied warranties or representations with respect to
                    the
                    Affiliate Program, about Company or the Commission payment arrangements (including, without
                    limitation,
                    functionality, warranties of fitness, merchantability, legality or non-infringement). In addition,
                    we make no
                    representation that the operation of our sites will be uninterrupted or error-free and will not be
                    liable for
                    the consequences if there are any. In the event of a discrepancy between the reports offered in the
                    Affiliate
                    Account system and the Company database, the database shall be deemed accurate.
                </p>
                <p>
                    <b className="text-indigo-400">10.2. Indemnity and Limitation of Liability:</b> You shall indemnify and hold Company, our
                    directors,
                    employees and representatives harmless from and against any and all liabilities, losses, damages and
                    costs,
                    including legal fees, resulting from or in any way connected with (a) any breach by you of any
                    provision of the
                    Affiliate Agreement, (b) the performance of your duties and obligations under the Affiliate
                    Agreement, (c) your
                    negligence or (d) any injury caused directly or indirectly by your negligent or intentional acts or
                    omissions,
                    or the unauthorised use of our creatives and links or this Affiliate Program.
                </p>
                <p>
                    The Company shall not be held liable for any direct or indirect, special, or consequential damages
                    (or any loss
                    of revenue, profits, or data), any loss of goodwill or reputation arising in connection with the
                    Affiliate
                    Agreement or the Affiliate Program, even if we have been advised of the possibility of such damages.
                </p>
                <p>
                    <b className="text-indigo-400">10.3. Non-Waiver:</b> Our failure to enforce your strict performance of any provision of the
                    Affiliate
                    Agreement will not constitute a waiver of our right to subsequently enforce such provision or any
                    other
                    provision of the Affiliate Agreement.
                </p>
                <p>
                    <b className="text-indigo-400">10.4. Relationship of Parties:</b> The Company and the Affiliate are independent contractors and
                    nothing in
                    the Affiliate Agreement will create any partnership, joint venture, agency, franchise, sales
                    representative, or
                    employment relationship between us. You will have no authority to make or accept any offers or
                    representations
                    on our behalf. You will not make any statement, whether on your site or otherwise, that would
                    contradict
                    anything in this Affiliate Agreement.
                </p>
                <p>
                    <b className="text-indigo-400">10.5. Force Majeure:</b> Neither party shall be liable to the other for any delay or failure to
                    perform its
                    obligations under the Affiliate Agreement if such delay or failure arises from a cause beyond its
                    reasonable
                    control, including but not limited to labour disputes, strikes, industrial disturbances, acts of
                    God, acts of
                    terrorism, floods, lightning, utility or communications failures, earthquakes or other casualty. If
                    such event
                    occurs, the non-performing Party is excused from whatever performance is prevented by the event to
                    the extent
                    prevented provided that if the force majeure event subsists for a period exceeding thirty (30)
                    calendar days
                    then either Party may terminate the Affiliate Agreement with immediate effect by providing a written
                    notice.
                </p>
                <p>
                    <b className="text-indigo-400">10.6. Assignability:</b> You may not assign the Affiliate Agreement, by operation of law or
                    otherwise,
                    without our prior written consent.
                </p>
                <p>
                    <b className="text-indigo-400">10.7. Severability:</b> If any provision of the Affiliate Agreement is held to be invalid,
                    illegal or
                    unenforceable in any respect, such provision will be ineffective only to the extent of such
                    invalidity, or
                    unenforceability, without invalidating the remainder of the Affiliate Agreement or any provision
                    hereof.
                </p>
                <p>
                    <b className="text-indigo-400">10.8. English language:</b> The Affiliate Agreement was first drafted in English. Should there be
                    any
                    conflict or discrepancy between the English language version and any other language, the English
                    version shall
                    prevail.
                </p>
                <p>
                    <b className="text-indigo-400">10.9. Modification of Terms & Conditions:</b> We may modify any of the terms and conditions
                    contained in the
                    Affiliate Agreement or replace it at any time and in our sole discretion by posting a change notice
                    or a new
                    agreement on our site. Modifications may include, for example, changes in the scope of available
                    Commissions
                    and Affiliate Program rules.
                </p>
                <p>
                    If any modification is unacceptable to you, you need to terminate the Affiliate Agreement. Your
                    continued
                    participation in our Affiliate Program following our posting of a change notice or new agreement
                    will constitute
                    binding acceptance of the modification or of the new agreement.
                </p>
            </div>


        </div>
    );
}

export default TermsAndConditions;