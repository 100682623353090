import React, {useState} from "react";
import logo from "../assets/navbar-assets/logo.svg"

import {GiHamburgerMenu} from "react-icons/gi";

import {TfiClose} from "react-icons/tfi";


const Navbar = () => {
    let Links = [
        {name: "About Us", link: "#about"},
        {name: "Why Us?", link: "#whyUs"},
        {name: "Commission", link: "#commission"},
        {name: "Testimonials", link: "#testimonials"},
        {name: "FAQ", link: "#faqs"},
        {name: "Contact Us", link: "#contactUs"},
    ];
    let [open, setOpen] = useState(false);

    const scrollToSection = (sectionId, offset = -100) => {
        const section = document.querySelector(sectionId);
        if (section) {
            const topPosition = section.getBoundingClientRect().top + window.scrollY;
            window.scrollTo({ top: topPosition + offset, behavior: 'smooth' });
        }
        setOpen(false);
    };
    return (
        <div className='shadow-md w-full bg-gradient-to-r from-gray-900 to-blue-950 sticky top-0 left-0 z-30'>
            <div className='md:flex items-center justify-between max-w-[1600px] mx-auto  py-4 md:px-10 lg:px-3 px-7'>

                <div className=''>
                    <img src={logo} alt="" className="h-[32px] lg:h-fit "/>
                </div>
                <div onClick={() => setOpen(!open)}
                     className='text-3xl text-white absolute right-8 top-4 md:top-4 cursor-pointer lg:hidden'>
                    {open ? (
                        <TfiClose/>
                    ) : (
                        <GiHamburgerMenu />
                    )}
                </div>
                <ul className={`md:flex md:justify-start lg:justify-start  md:flex-col lg:flex-row md:gap-5 lg:gap-0 
                     lg:flex-1  
                    lg:pb-0 pb-12 absolute lg:static 
                     left-0 w-full lg:w-auto shadow-md lg:shadow-none  text-start  transition-all duration-500 ease-in
                       ${open ? 'top-16  bg-gradient-to-r from-gray-900 to-blue-950 lg:bg-none' : 'top-[-700px] bg-gradient-to-r from-gray-900 to-blue-950 lg:bg-none '} `}>
                    <div className="border-b border-[#7F87F9] mx-7  mb-20 lg:mb-0"></div>
                    {Links.map((link) =>
                        (<li key={link.name} className=' xl:ml-10 md:my-0 my-7 pl-7 md:pl-9 lg:pl-2 md:mb-5 lg:mb-0 '>
                            <button
                                onClick={() => scrollToSection(link.link, -100)}
                                className='hover:text-indigo-400 duration-500 text-xl lg:text-base font-bold lg:font-normal'>
                                {link.name}
                            </button>
                        </li>))}
                    <div className="flex flex-row justify-center lg:hidden gap-4 mt-20 ">
                        <a href="https://admin.bespokeaffiliates.com/partner/login"> <button className="text-indigo-400 border border-indigo-400 rounded-3xl py-2   px-12 ">Login</button></a>
                        <a href="https://admin.bespokeaffiliates.com/partner/register"> <button className="bg-gradient-to-r from-indigo-900 to-indigo-400 rounded-3xl px-10 py-[9px]  "> Sign Up
                        </button></a>
                    </div>
                </ul>
                {/*<img src={vectoro} alt="" className="absolute  top-0 lg:hidden w-96"/>*/}
                <div className="lg:flex flex-col md:flex-row hidden  gap-4">
                    <a href="https://admin.bespokeaffiliates.com/partner/login"> <button className="text-indigo-400 border border-indigo-400 rounded-3xl py-2  px-10 ">Login</button></a>
                    <a href="https://admin.bespokeaffiliates.com/partner/register"> <button className="bg-gradient-to-r from-indigo-900 to-indigo-400 hover:from-indigo-400 hover:to-indigo-900 text-white font-bold  rounded-3xl transition duration-300 ease-in-out transform hover:scale-105l px-8 py-[9px]"> Sign Up
                    </button></a>
                </div>
            </div>
        </div>
    )
}

export default Navbar;