import Faq from "./Faq";
import React, {useEffect} from "react";
import {TfiClose} from "react-icons/tfi";

const AuthModalFaq = ({onClose}) => {

        const handleClickOutsideFaq = (e) => {
            if (e.target.classList.contains('overlay')) {
                onClose();
            }
        };

    const handleKeyDownFaq = (e) => {
        if (e.key === 'Escape') {
            onClose();
        }
    }
    useEffect(() => {
        window.addEventListener('keydown', handleKeyDownFaq);

        return () => {
            window.removeEventListener('keydown', handleKeyDownFaq);
        };
    }, );

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center ">
            <div className="overlay absolute inset-0 bg-black bg-opacity-60" onClick={handleClickOutsideFaq}></div>
            <div className="z-10 p-6  rounded shadow-md max-w-fit overflow-hidden">

                <div className="max-h-[800px] overflow-y-auto relative mt-10  ">
                    <button className="absolute right-7 top-44 md:right-14 md:top-44 lg:right-5 lg:top-44" onClick={onClose}>
                        <TfiClose className=""/>
                    </button>
                    <Faq/>

                </div>

            </div>
        </div>

    )
}

export default AuthModalFaq;