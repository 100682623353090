import React from "react";
import {Carousel} from "flowbite-react";
import commaDown from "../assets/Section5/commaDown.svg";
import commaUp from "../assets/Section5/commaUp.svg";



export default function SlideAsAnythingg() {
    return (
        <Carousel className=" h-[260px] px-3 md:px-20 mt-10 font-bold text-[#819DC8] block lg:hidden text-white" slide="">

            <div
                className="flex flex-col items-center justify-center rounded-lg shadow-xl bg-gradient-to-r from-gray-900 to-blue-950 h-[260px]  lg:w-[500px] lg:h-[235px]">
                <img src={commaDown} alt="" className="w-[17.856px] mb-2"/>
                <div className="flex flex-col items-start px-7">
                    <p className="italic"> The team behind Bespoke Affiliates is all about successful
                        partnerships and their
                        clients’ satisfaction. If you’re in search of a reliable affiliate program, this one
                        is
                        definitely worth looking into.
                    </p>
                    <h2 className="font-bold text-indigo-400 ">Casinoisy</h2>
                </div>
                <img src={commaUp} alt="" className="w-[17.856px] mt-2"/>
            </div>
            <div
                className="flex flex-col items-center justify-center rounded-lg shadow-2xl bg-gradient-to-r from-gray-900 to-blue-950 h-[260px]  lg:w-[500px] lg:h-[235px] opacity-90">
                <img src={commaDown} alt="" className="w-[17.856px] mb-2"/>
                <div className="flex flex-col items-start px-7">
                    <p className="italic"> This is one of the rare affiliate programs worth collaborating
                        with because the
                        expertise of the BA team is shown in everything they do. It is a pleasure working
                        with them, not only for their expertise but for their trustworthiness as well.
                    </p>
                    <h2 className="font-bold text-indigo-400">AblePlay</h2>
                </div>
                <img src={commaUp} alt="" className="w-[17.856px] mt-2"/>
            </div>
            <div
                className="flex flex-col items-center justify-center rounded-lg shadow-2xl bg-gradient-to-r from-gray-900 to-blue-950 h-[260px]  lg:w-[500px] lg:h-[235px]">
                <img src={commaDown} alt="" className="w-[17.856px] mb-2"/>
                <div className="flex flex-col items-start px-7">
                    <p className="italic"> Bespoke Affiliates is a new exciting affiliate program launched
                        by a group of
                        individuals who have proved to know this industry very well. This is a great
                        product, with a strong team and innovative vision.
                    </p>
                    <h2 className="font-bold text-indigo-400">Casino Affiliate Programs</h2>
                </div>
                <img src={commaUp} alt="" className="w-[17.856px] mt-2"/>
            </div>
            <div
                className="flex flex-col items-center justify-center rounded-lg shadow-2xl bg-gradient-to-r from-gray-900 to-blue-950 h-[260px]  lg:w-[500px] lg:h-[235px] opacity-90">
                <img src={commaDown} alt="" className="w-[17.856px] mb-2"/>
                <div className="flex flex-col items-start px-7">
                    <p className="italic"> The Bespoke Affiliate team really understand what affiliates want
                        and need for
                        successful partnerships. It's a pleasure working with them, and would highly
                        recommend them to affiliates looking for a trust worthy programs.
                    </p>
                    <h2 className="font-bold text-indigo-400">TagMedia</h2>
                </div>
                <img src={commaUp} alt="" className="w-[17.856px] mt-2"/>
            </div>

        </Carousel>
    )
}