import React, {useState} from "react";
import arrow from "../assets/faq-assets/arrow.png";
const Accordion = ({imga, title, content, contenttwo}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    }

    return (
        <div className="">
            <div className="py-3 cursor-pointer flex flex-row items-center justify-between" onClick={toggleAccordion}>
                <img src={imga} alt="" className="mr-5"/>
                <h2 className="font-semibold mr-auto">{title}</h2>
                <span className={`transform transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}>
                <img src={arrow} alt=""/>
            </span>
            </div>
            <div className={`overflow-hidden transition-max-height duration-500 ${isOpen ? 'max-h-[1000px]' : 'max-h-0'}`}>
                <div className="px-10 pb-3 ml-3">{content}</div>
                <div className="px-10 pb-3 ml-3">{contenttwo}</div>
            </div>
            <div className="border-b border-[#1D3150] "></div>
        </div>
    );
}

export default Accordion;