import React from "react";

const PrivacyPolicy = () => {


    return (
        <div className="max-w-[1450px] mx-auto bg-[#111C2D]  flex flex-col  py-10   px-3 md:px-10 shadow-lg ">
            <div>
                <div className="text-center text-3xl font-bold mb-20 ">
                    <h2>Privacy Policy</h2>

                </div>
                <p><span className="text-indigo-400 font-bold">Bespoke Affiliates</span> management team and its
                    employees take the safety of your personal data and
                    general confidentiality very seriously. We are committed to improving the transparency of how we use
                    and protect your data. We have included changes to this Privacy Policy to comply with the standards
                    of the General Data Protection Regulation (GDPR). This Privacy Policy sets out our data processing
                    methods and your options concerning how your personal information is used. <span
                        className="text-indigo-400 font-bold">Bespoke Affiliates</span>
                    understands and takes responsibility for providing you, our customers, with clear information about
                    how we use cookies, and we guarantee that people using our website can make the right choice. If you
                    have any requests, questions, or suggestions regarding your personal data or any general questions
                    regarding methods of processing and using the services provided to you, please contact us at
                    info@bespokeaffiliates.com. We also kindly ask that you send detailed information to
                    info@bespokeaffiliates.com about any pages on our website where you are concerned about the use of
                    cookies
                </p>
            </div>
            <div id="Privacy">
                <h2 className="text-2xl font-bold my-10 text-indigo-400"><span
                    className="font-bold text-indigo-400">1.</span> Privacy</h2>
                <p><span className="font-bold text-indigo-400">Bespoke Affiliates </span>undertakes to protect your
                    personal data.
                    This Privacy Policy explains what information we collect when our services are used, why we collect
                    this information, and how we use it.
                    Please note this Privacy Policy will be agreed between you and <span
                        className="text-indigo-400 font-bold">Bespoke Affiliates</span> (hereinafter «We»,
                    «Us» or «Our», if applicable).
                    We may periodically make changes to this Privacy Policy and notify you of these changes by
                    publishing the modified terms on our platforms.
                    We recommend that you review this Privacy Policy regularly.
                </p>
            </div>
            <div id="Consent">
                <h4 className="text-2xl font-bold my-10 text-indigo-400">2. Consent To Personal Data
                    Processing</h4>
                <p className="">RIGHTS OF THE PERSONAL DATA SUBJECT</p>
                <p className="">The processing of your personal data at <span className="text-indigo-400 font-bold">Bespoke Affiliates</span> starts
                    only
                    after you have clearly
                    expressed the approval that establishes and confirms freely given, explicit, informed,
                    and unambiguous expression of your consent to the processing of your personal
                    data (hereinafter «Consent»).
                    The Consent you give us freely, voluntarily and in your interest is explicit, informed,
                    and conscious.
                    Your consent to the processing of personal data may be given to us by you or your
                    representative in any form that allows <span className="text-indigo-400 font-bold">Bespoke Affiliates</span> to
                    confirm that it was received,
                    namely:<br/>
                    -IN WRITING<br/>
                    In this event the Consent should contain the following information:
                    <ul>
                        <li>surname, first name, patronymic (if applicable), address of the subject of
                            personal data, number of the main identification document, document issue
                            date and the issuing authority, or surname, first name, patronymic, address of
                            the representative of the personal data subject, the number, issue date, and
                            issuing authority of their main identification document, the requisites of the
                            notarized power of attorney or other document confirming the authority of
                            that representative (upon receipt of the consent from the representative of the
                            personal data subject)
                        </li>
                        <li>the postal address of <span
                            className="text-indigo-400 font-bold">Bespoke Affiliates</span> receiving the consent of the
                            personal data
                            subject
                        </li>
                        <li>the purposes of processing the personal data</li>
                        <li>a list of the personal data, for the processing of which the consent of the
                            subject of personal data is given
                        </li>
                        <li>the name and address of the person (legal entity) that is processing the
                            personal data on behalf of <span
                                className="text-indigo-400 font-bold">Bespoke Affiliates</span>, if the processing is
                            delegated to such
                            person (entity)
                        </li>
                        <li>a list of actions on personal data to which the consent is given; general
                            overview of the personal data processing methods
                        </li>
                        <li>the period during which the consent of the subject of personal data is valid, as
                            well as the method of its withdrawal
                        </li>
                        <li>the signature of the personal data subject</li>
                    </ul>
                    - BY WAY OF IMPLICATIVE ACTIONS OF a personal data subject which express their
                    will to establish a legal relationship with <span className="text-indigo-400 font-bold">Bespoke Affiliates</span>,
                    not in the form of an oral or
                    written expression of their will, but by their behavior, from which such intention can
                    be concluded (for example, registration on the promoting website, registration to
                    <span className="text-indigo-400 font-bold">Bespoke Affiliates</span> services, execution of
                    monetary transactions into their personal account
                    obtained through registration on the <span
                        className="text-indigo-400 font-bold">Bespoke Affiliates</span> website, which is a condition
                    for
                    participating in betting in accordance with the Terms and Conditions of<span
                        className="text-indigo-400 font-bold">Bespoke Affiliates</span>,
                    registration of bets through their personal account and receipt of winnings through
                    their personal account, compliance with the rules established by the gambling
                    operator, etc.).
                    <span className="text-indigo-400 font-bold">Bespoke Affiliates</span> does not require your Consent
                    to process your personal data in the
                    following cases:
                    <ul>
                        <li>where the processing of personal data is necessary to achieve the objectives
                            provided for by international treaties or laws of local jurisdictions, for the
                            performance and discharge of the functions, powers, and duties conferred by
                            law;
                        </li>
                        <li>where processing of personal data is necessary for the administration of
                            justice, the execution of a court decision or a decision by another legal body
                            or an official subject to execution in accordance with local laws;
                        </li>
                        <li>where processing of personal data is necessary to protect the life, health, or
                            other vital interests of the subject of personal data if obtaining their consent
                            is not possible;
                        </li>
                        <li>where processing of personal data is necessary for the purposes of
                            exercising the rights and legitimate interests of <span
                                className="text-indigo-400 font-bold">Bespoke Affiliates</span> or third parties,
                            or
                            for the achievement of socially significant purposes, provided that this does
                            not violate the rights and freedoms of your personal data;
                        </li>
                        <li>where processing of personal data is carried out for statistical or other
                            research purposes, subject to obligatory anonymization of your personal data,
                            except when personal data is processed for marketing purposes;
                            where access to the personal data that is being processed has been provided
                            by you to an unlimited number of persons;
                        </li>
                        <li>where personal data that is being processed is subject to publication or
                            mandatory disclosure in accordance with the laws of local jurisdictions.
                        </li>
                    </ul>
                    The procedures for the withdrawal of your consent to the processing of your
                    personal data, obtaining information about the availability of your personal data in
                    <span className="text-indigo-400 font-bold">Bespoke Affiliates</span>, and clarifying, blocking, and
                    destroying your personal data which
                    <span className="text-indigo-400 font-bold">Bespoke Affiliates</span> holds on request are governed
                    by the «General Data Protection
                    Regulation» (GDPR) and are carried out by us in full.
                </p>
            </div>
            <div id="Objectives">
                <h4 className="text-2xl font-bold my-10 text-indigo-400">3. Objectives Of
                    Information Collection</h4>
                <p className="">We collect and process information that can be used for personal
                    identification
                    purposes, including, but not limited to, first name and surname, date of birth, credit
                    card details, home or other address, e-mail address, telephone number or other
                    appropriate Personal information (hereinafter «Information»).<br/><br/>
                    You may be asked to provide personal information when using our website,
                    registering an account or using our services.<br/><br/>The personal information we collect may
                    include information such as:
                    <ul>
                        <li>contact details (including phone number, e-mail address);</li>
                        <li>information on residency (your geographic postal address);</li>
                        <li>payment information</li>
                        <li>transaction history</li>
                        <li>preferences when using the website;</li>
                        <li>feedback on services</li>
                    </ul>
                    This information is collected, processed, and stored by us on servers, according to
                    the requirements of the legislation of your state.<br/><br/>
                    When you interact with services, our servers store your unique activity log, which
                    collects certain administrative and traffic information, including:
                    <ul>
                        <li>source IP address;</li>
                        <li>access time;</li>
                        <li>date of access;</li>
                        <li>visited web pages;</li>
                        <li>language used;</li>
                        <li>software crash reports</li>
                        <li>type of browser used</li>
                    </ul>
                    This information is needed to ensure we provide services of the highest quality.<br/><br/>
                    We never collect personal information about you without your knowledge.
                </p>
            </div>
            <div id="Means">
                <h4 className="text-2xl font-bold my-10 text-indigo-400">4. Means Of Gathering And Processing Data</h4>
                <p className="">We may automatically collect certain data, as discussed above, and receive Personal
                    Information about you where you provide such information voluntarily through the
                    services or other communications and interactions on the <span
                        className="text-indigo-400 font-bold">Bespoke Affiliates</span> site.<br/><br/>We may
                    also receive personal information from online vendors and service
                    providers, and from customer lists lawfully acquired from third-party suppliers.<br/><br/>In
                    addition, we may use the services of third - party service suppliers for technical
                    support of your online transactions and for maintaining your account.<br/><br/>We will have access
                    to any information you provide to such suppliers, service
                    providers, and third - party e-commerce services. We will use the Personal
                    Information in accordance with the provisions of this Privacy Policy.<br/><br/>This information will
                    be disclosed to third parties outside the company only in
                    accordance with this Privacy Policy and the legislation of your state.
                </p>
            </div>
            <div id="Use">
                <h4 className="text-2xl font-bold my-10 text-indigo-400">5. The Use Of Data</h4>
                <p className="">We use the personal information that you provide to us to deliver our Services, to
                    provide customer support, to ensure the necessary security, for the identity
                    verification and checks, to process any of your online transactions, as well as to
                    assist your participation in third - party promotions, that meet certain business
                    conditions, and for any other purposes related to the operation of our gaming
                    Services.<br/><br/>Therefore, during the provision of our services we may share your personal
                    information with our carefully selected partners (including any other parties that
                    have arrangements with you about the sharing of your data).<br/><br/>Your personal information may
                    also be used by us to provide you with:
                    <ul>
                        <li>promotional offers and information about our products and services;</li>
                        <li>promotional offers and information about our partners’ products and services,
                            in order to enlarge the range of products provided to you and improve our
                            customer service
                        </li>
                    </ul>
                    From time to time, we may request information from you via surveys or
                    competitions. Participation in these surveys or competitions is completely voluntary
                    and you have the choice of whether or not to disclose your personal information.<br/><br/>Information
                    requested may include contact details (such as name, correspondence
                    address, telephone number), and geographic information (such as postal code or
                    postal address), age.<br/><br/>By taking part in any competition or accepting winnings(prizes) from
                    us, you consent
                    to the use of your name for promotional purposes without additional remuneration,
                    except where prohibited by law.<br/><br/>If you have not unequivocally decided whether to receive
                    promotional information
                    from us, we may use your personal information(including your e - mail address and
                    phone number) to provide you with information regarding our products, services, and
                    promotions, including other gaming products(including online poker, casino, betting,
                    backgammon etc.) and third - party products and services carefully selected by us.
                </p>
            </div>
            <div id="Information">
                <h4 className="text-2xl font-bold my-10 text-indigo-400">6. Information Disclosure</h4>
                <p className="">We do not disclose your Personal Information to companies, organizations or
                    individuals not associated with <span
                        className="text-indigo-400 font-bold">Bespoke Affiliates</span>.<br/>We may disclose your
                    Personal Information to
                    companies, organizations or
                    individuals not associated with <span
                        className="text-indigo-400 font-bold">Bespoke Affiliates</span> if you have given us consent to
                    these
                    actions.<br/>We may disclose your Personal Information if required to do so by applicable law, or
                    if we believe in good faith that such actions are necessary to:
                    <ul>
                        <li>comply with any legal issue or process that concerns us, any of our websites
                            or services or in circumstances where we are essentially bound by legal
                            obligation
                        </li>
                        <li>protect our rights or property</li>
                        <li>protect the personal safety of our service users or the public<br/><br/>If, in our opinion
                            and sole determination, you are found to have deceived us or
                            attempted to deceive us, or any other service user in any way including but not
                            limited to:<br/><br/>
                            <li>game tampering;</li>
                            <li>payment fraud.</li>
                        </li>
                    </ul>
                    If we have grounds to suspect you of payment fraud, including the use of stolen
                    credit cards, or any other fraudulent activity, including any payment reversal or other,
                    payment cancellation, or prohibited transactions, including money laundering, we
                    reserve the right to share this information together with your identity information
                    with other online gaming sites, banks, credit card companies, appropriate regulatory
                    agencies, and relevant law enforcement authorities.<br/><br/>
                    For the purpose of public research on the prevention of addiction, your data can be
                    passed on to the relevant institutions.
                </p>
            </div>
            <div id="Access">
                <h4 className="text-2xl font-bold my-10 text-indigo-400"> 7. Access</h4>
                <p>You may ‘opt out’ of receiving any promotional communications either.</p>
                <ul>
                    <li>by choosing to opt out via your account settings available on our sites or
                        through our services;
                    </li>
                    <li>in response to a promotional email you receive from us;</li>
                    <li>at any time by sending an e-mail, or by writing to our Customer Services.</li>
                </ul>
                In addition, you may contact us at any time if you:<br/><br/>
                <ul>
                    <li>want to find out what of your personal information we collect, process, and
                        store, and also from which sources we have obtained it;
                    </li>
                    <li>want to confirm the accuracy of the personal information we have collected
                        about you;
                    </li>
                    <li>would like to update your personal information; and/or</li>
                    <li>have any complaint regarding our use of your Personal Information.</li>
                </ul>
                If required, we will update any information you have provided to us, given that you
                prove the necessity for such changes and verify your identity.<br/>
                For the avoidance of doubt, nothing in this Privacy Policy shall allow us to retain your
                Personal Information if we are required to provide it by the law of your country.
            </div>
            <div id="Cookies">
                <h4 className="text-2xl font-bold my-10 text-indigo-400">8. Cookies</h4>
                <p className="">INFORMATION STORED ON YOUR DEVICE<br/><br/>
                    When accessing our services, with your consent we may store information on your device. This
                    information is referred to as ‘cookies’, which are small text files containing letters and numbers
                    for recording your preferences. Cookies are stored on your device when you use our services, while
                    visiting our websites and online pages.
                    <br/>We also use local shared objects or ‘flash cookies’. ‘Flash cookies’ are similar to browser
                    cookies. They allow us to remember your visits across our sites.<br/>
                    Neither cookies nor flash cookies can be used to access your device or use information on your
                    computer.<br/>
                    We only use cookies and ‘flash cookies’ for monitoring.<br/>
                    We only use these methods to track your use of our services to record your preferences.<br/>
                    Cookies help us monitor traffic on our site, improve our services, make it easier for you to access
                    them and increase your interest in our services.<br/>
                    We use flash cookies and other cookies to help us show you more relevant and targeted
                    advertisements.<br/><br/>
                    STRICTLY NECESSARY COOKIES<br/><br/>Strictly necessary cookies are used to allow users to navigate
                    the website and use its features, such as accessing secure areas of the website or making financial
                    transactions. Without these cookies, you would not be able to use our websites
                    efficiently.<br/><br/>THE
                    REGISTRATION PROCESS<br/><br/>These cookies will hold information collected during your
                    registration and will allow us to recognize you as a customer and provide you with the
                    services you require. We may also use this data to better understand your online interests
                    and preferences and to constantly enhance your visits to our platforms and use of our
                    services.<br/><br/>OUR WEBSITE<br/><br/>We use cookies to collect information for visitors to
                    our website.<br/><br/>
                    <ul>
                        Our servers use three different types of cookies:
                        <li>«Session-based» cookies: This type of cookie is only allocated to your
                            computer for the duration of your visit to our website. A session-based
                            cookie helps you navigate our website faster and, if you are a registered
                            customer, allows us to give you information that is more relevant to you.
                            This cookie automatically expires when you close your browser.</li>
                        <li>«Persistent» cookies: This type of cookie will remain on your computer for a
                            set period of time for each cookie. Flash cookies are also persistent.
                        </li>
                        <li>«Analytical» cookies: This type of cookie allows us to recognize and count
                            the number of visitors to our site and see how visitors use our services.
                            This helps us improve the way our sites work, for example, by ensuring you
                            can log in and find what you are looking for easily.
                        </li>
                    </ul>
                    <br/>You make the decision and you always have a choice of whether to accept or
                    decline cookies.<br/><br/>Most web browsers automatically accept cookies, but, if
                    you prefer, you can modify your browser settings to keep control over your
                    cookies.
                    <ul>You can use your web browser to:
                        <li>delete all cookies;</li>
                        <li>block all cookies;</li>
                        <li>allow all cookies;</li>
                        <li>block third-party cookies;</li>
                        <li>clear all cookies when the browser is closed;</li>
                        <li>open a "private browsing"/"incognito" session that allows you to
                            browse the Internet without storing data locally;
                        </li>
                        <li>install add-ons and plug-ins to extend browser options.</li>
                    </ul>
                    <ul>Where can I find information about managing cookies?
                        <li><a href="https://support.microsoft.com/kb/278835">Information about
                            cookies in Internet Explorer</a></li>
                        <li><a href="https://support.google.com/chrome/answer/95647?hl=en-GB">Information
                            about cookies in Chrome</a></li>
                        <li><a
                            href="https://support.mozilla.org/en-US/kb/Clear%20Recent%20History">Information
                            about cookies in Firefox</a></li>
                        <li><a href="https://support.apple.com/kb/PH5042">Information about
                            cookies in Safari</a></li>
                        <li><a href="https://www.opera.com/help/tutorials/security/privacy/">Information
                            about cookies in Opera</a></li>
                    </ul>
                    <br/>
                    FLASH COOKIES<br/><br/>You can modify your Flash Player settings to
                    prevent the use of flash cookies. The settings manager of your Flash
                    Player allows you to manage your preferences.<br/><br/>If you choose to
                    decline all cookies in the browser, unfortunately you will not be
                    able to use some of the features and services on our websites, and
                    some services will not work correctly, for example, we will not be
                    able to save your chosen interface language.
                </p>
            </div>
            <div id="ConsentService">
                <h4 className="text-2xl font-bold my-10 text-indigo-400">9.Consent to ue of
                    electronic service providers</h4>
                <p className="">In order to play real money games on our services, you will be required to
                    transfer money to and receive money from us. To facilitate such services we may use third - party
                    electronic payment systems to process the required financial transactions.<br/><br/>By agreeing to
                    and
                    accepting this Privacy Policy, you expressly and voluntarily consent to the transfer of your
                    personal information to third parties which is necessary for the processing and completion of
                    monetary transactions including, when necessary, the transfer of your personal information
                    outside of your country.<br/><br/>We take steps to ensure that your privacy is protected when
                    using third party payment systems
                </p>
            </div>
            <div id="Security">
                <h4 className="text-2xl font-bold my-10 text-indigo-400">10. Consent to security
                    reviews</h4>
                <p className="text-left p-2">We reserve the right to conduct a security review at any time to validate
                    the registration details provided by you and to verify your financial transactions and the correct
                    use of our services by you, in order to prevent potential breaches of our Terms and Conditions and
                    of any applicable law.<br/><br/>By using our services and thereby agreeing to our Terms and
                    Conditions, you authorize us to use your Personal Information and to disclose your Personal
                    Information to third parties for the purposes of validating the information you provide during
                    registration and use of our Services, including, where necessary, the transfer of your personal
                    information outside of your country.<br/><br/>Security reviews may include but are not limited to
                    ordering a credit report and otherwise verifying the information you provide against third -
                    party databases.
                </p>
            </div>
            <div id="Securityy">
                <h4 className="text-2xl font-bold my-10 text-indigo-400">11. SECURITY</h4>
                <p className="">We understand the importance of providing security and the methods needed
                    to secure the confidentiality, integrity, and accessibility of information.We store all personal
                    information we receive directly from you in an encrypted and password -protected database stored
                    within our secure network behind active up-to-date firewall software.<br/><br/>Our Services support
                    SSL Version 3 with 128-bit encryption.<br/><br/>We also take measures to ensure our subsidiaries,
                    agents, partners, affiliates, and suppliers employ adequate security measures.<br/><br/>However,
                    sending information via the internet is usually not completely secure, and we cannot
                    guarantee the security of your data while it is being sent.<br/><br/>ANY DATA YOU SEND, IS
                    SENT AT YOUR OWN RISK.<br/><br/><span
                        className="text-indigo-400 font-bold">Bespoke Affiliates</span> has all security procedures and
                    features in place to protect your data after it is received.
                </p>
            </div>
            <div id="Protection">
                <h4 className="text-2xl font-bold my-10 text-indigo-400">12. Protection of minors
                </h4>
                <p className="">Our services are not intended for persons under the age of eighteen (18) or
                    the lawful age in their respective jurisdiction.<br/><br/>Any person who provides their information
                    to
                    us through any part of the services confirms to us that they are eighteen (18) years of age (or
                    the lawful age in their respective jurisdiction) or older.<br/><br/>It is our policy to uncover
                    attempts by minors to access our services which may require an additional security
                    review.<br/><br/>If we become aware that a minor has attempted to or has submitted personal
                    information via our services, we will not accept their information and will take all
                    steps to purge the information from our records.
                </p>
            </div>
            <div id="International">
                <h4 className="text-2xl font-bold my-10 text-indigo-400">13. International transfers
                </h4>
                <p className="">Personal Information collected by us may be stored and processed in any
                    country in which we or our affiliates, suppliers, partners or agents provide gaming services and
                    services of our gaming platform.<br/><br/>By using our services, you expressly consent to any
                    transfer
                    of information outside your country (including to countries that may not be considered as having
                    adequate privacy laws).<br/><br/>Nevertheless, we take steps to ensure that our agents, partners,
                    affiliates, and suppliers comply with our standards of privacy and this Privacy Policy
                    regardless of their location.
                </p>
            </div>
            <div id="Third">
                <h4 className="text-2xl font-bold my-10 text-indigo-400">14. Third-party
                    relationships</h4>
                <p className="">We cannot ensure the protection of any information that you provide to a
                    third-party website that links to our services or of any other information collected by any third
                    party managing it in compliance with our <span className="text-indigo-400 font-bold">Bespoke Affiliates</span> (if
                    applicable) or otherwise, since
                    these third-party online services and websites are not owned by us and are operated independently
                    from us.<br/><br/>Be careful.<br/><br/>Any information collected by these third parties is governed
                    by
                    the privacy policy, if any, of such third parties.
                </p>
            </div>
            <div id="legal">
                <h4 className="text-2xl font-bold my-10 text-indigo-400">15. Legal Disclaimer</h4>
                <p className="">We are not responsible for events beyond our direct control.<br/><br/>Due to the
                    complex and ever-changing nature of our technologies and business, we provide comprehensive, but we
                    do not guarantee an error-free operation regarding the confidentiality of your personal information
                    when you visit other websites using links located on our website. Websites that are beyond our
                    control, and especially not covered by this Privacy Policy, should provide you with an opportunity
                    to familiarise yourself with their security policies. If you visit other websites, you should be
                    aware that the operators of these websites can collect your personal information, which they will
                    use in accordance with their privacy policy, which will differ from ours.<br/><br/>Be
                    careful.<br/><br/>We
                    do not guarantee the security of your data, while it is being transmitted through channels of
                    communication.<br/><br/>ANY DATA YOU SEND, IS SENT AT YOUR OWN RISK.<br/><br/>We are also not
                    responsible for any direct or indirect damage arising from the unlawful use or theft of your
                    Personal Information.
                </p>
            </div>
            <div id="consentPrivacy">
                <h4 className="text-2xl font-bold my-10 text-indigo-400">16. Consent to privacy policy</h4>
                <p>Your use of our services is your voluntary agreement with our Privacy Policy
                    and you thereby give us the right to collect, process, and store your personal data.<br/><br/>This
                    Privacy Policy should be read in conjunction with our Terms and Conditions and any additional
                    applicable Terms and Conditions published on our platforms and websites.<br/><br/>We may
                    periodically make changes to this Privacy Policy and will notify you of these changes by
                    publishing the modified terms on our platforms and websites.<br/><br/>Your continued use of
                    our services following any changes to this Privacy Policy constitutes your acceptance of
                    the changes.<br/><br/>This is our entire and exclusive Privacy Policy on May 16, 2018, it
                    supersedes any earlier version and applies only to <span className="text-indigo-400 font-bold">Bespoke Affiliates</span><br/><br/>We
                    recommend that you revisit this Privacy Policy regularly.
                </p>
            </div>
        </div>

    )
}

export default PrivacyPolicy;