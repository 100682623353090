
const ThankYou = () => {
    return (
        <main
            className="max-w-[1360px] md:mx-24 lg:mx-auto bg-main bg-top bg-cover bg-no-repeat md:bg-right md:bg-cover md:bg-no-repeat text-white min-h-[60vh]">
            <div className="flex flex-col items-center py-20 px-4">
                <div className="bg-[#0C142C] p-8 rounded-lg max-w-2xl w-full text-center">
                    <h1 className="text-xl md:text-4xl font-bold mb-6 bg-[#0C142C]">
                        Thank You for Subscribing!
                    </h1>
                    <div className="space-y-4 mb-8 bg-[#0C142C]">
                        <p className="text-lg  bg-[#0C142C]">
                            Welcome to our community! You are now all set to receive the latest updates and insights.
                        </p>
                        <p className=" bg-[#0C142C]">
                            Check your inbox soon for our welcome email.
                        </p>
                    </div>
                    <a
                        href="/"
                        className="inline-block bg-gradient-to-r from-indigo-900 to-indigo-400 hover:from-indigo-400 hover:to-indigo-900 text-white font-bold py-3 px-6 rounded-3xl transition duration-300 ease-in-out transform hover:scale-105"
                    >
                        Back to Home
                    </a>
                </div>
            </div>
        </main>
    )
}

export default ThankYou