import Navbar from "./components/Navbar";
import HeroSection from "./components/HeroSection";
import ThirdSection from "./components/ThirdSection";
import Section4 from "./components/Section4";
import Section5 from "./components/Section5";
import Faq from "./components/Faq";
import Footer from "./components/Footer";
import { Routes, Route } from 'react-router-dom';
import AuthModalTerms from "./components/AuthModalTerms";
import AuthModalFaq from "./components/AuthModalFaq";
import ThankYou from "./components/ThankYou";

function App() {
    return (
        <div className="bg-gradient-to-r from-blue-950 to-gray-900 w-full text-white">
            <Navbar />
            <Routes>
                <Route path="/" element={
                    <>
                        <HeroSection />
                        <ThirdSection />
                        <Section4 />
                        <Section5 />
                        <Faq />
                    </>
                } />
                <Route path="/faq" element={<AuthModalFaq />} />
                <Route path="/terms" element={<AuthModalTerms />} />
                <Route path="/thank-you" element={<ThankYou />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default App;