import React from "react";
import Accordion from "./Accordion";
import handshake from "../assets/faq-assets/handshake.png";
import free from "../assets/faq-assets/free.png";
import arrows from "../assets/faq-assets/arrows.png";
import calculator from "../assets/faq-assets/caluclator.png";
import time from "../assets/faq-assets/time.png";
import curly from "../assets/faq-assets/curly.png";
import pictures from "../assets/faq-assets/pictures.png";
import world from "../assets/faq-assets/wolrd.png";
import cookies from "../assets/faq-assets/cookie.png";


const Faq = () => {

    const faqs = [{
        imga: handshake,
        title: "How do I become an Affiliate",
        content: "Becoming an Affiliate is very easy - it will take less than 2 minutes to register. After registration, " +
            "your account will be reviewеd by a moderator and approved if all the details have been entered correctly."
    },
        {
            imga: free,
            title: "Is this Program free?",
            content: "Yes! It is absolutely free to join our Bespoke Affiliates. All you need to do is register. " +
                "There is no charge and no requirement for a minimum number of players. "
        },
        {
            imga: arrows,
            title: "How often are my statistics updated?",
            content: "Statistics on the number of registrations and deposits are updated every hour. Clicks, views and direct referrals are updated in real-time. "
        },
        {
            imga: calculator,
            title: "How is my affiliate commission calculated?",
            content: "Commission = Net Gaming Revenue * Revenue Share %",
            contenttwo: "Net Gaming Revenue = Gross Gaming Revenue - Bonus Costs - Chargebacks - Admin fee"
        },
        {
            imga: time,
            title: "When will I receive my commission?",
            content: "The Commission is calculated at the end of each month and payments shall be made on a monthly basis in arrears, not later than the 10th of the following calendar month. "
        },
        {   imga: curly,
            title: "Do  you have Negative Carry-Over?",
            content: "No, we do not have negative carry-over. "
        },
        // {   imga: pictures,
        //     title: "What are the affiliate payment models?",
        //         content: (
        //             <div>
        //                 Our affiliate payment models include: <br />
        //                 1. Revenue Share. You will be getting % of losses of your referred customers. <br />
        //                 2. CPA - a fixed rate paid for each player who has met the minimum requirements. <br />
        //                 3. The referral system allows an Affiliate to earn additional revenue from other Affiliates you have recruited to join our Bespoke Affiliates (an Affiliate who is referred by another Affiliate is called a sub-affiliate). The Affiliate will receive a 3%-5% commission of the sub-affiliate's total profit.
        //             </div>
        //         ),
        // },
        {   imga: world,
            title: "What if I have more than one website?",
            content: "You can send customers from any number of websites. You can add more than one website to the Affiliate program. "
        },
        {   imga: cookies,
            title: "How long are cookies stored?",
            content: "Duration of cookies: 30 days."
        },
    ]

    return (
        <div className="mt-40 px-3 md:px-10 xl:px-0 z-20 relative ">
            <div className="max-w-[1450px] mx-auto bg-[#111C2D] flex flex-col md:flex-row py-10  px-3 md:px-10 shadow-lg " id="faqs">
                <div className="flex flex-col items-start  flex-shrink-0 mb-10 md:mb-0 ">
                    <h2 className="text-4xl font-bold mt-2 ">General FAQs</h2>
                    <p className="mt-2">Everything you need to know at one place.</p>
                </div>
                <div className=" md:pl-5 flex-1   ">
                    {faqs.map((faq, index) => (
                        <Accordion key={index} {...faq} />
                    ))}
                </div>
            </div>
        </div>
    )

}

export default Faq;