import React, { useState } from 'react';

const Subscription = () => {
    const [status, setStatus] = useState('idle');

    const formAction = "https://eu.customerioforms.com/forms/submit_action";
    const siteId = process.env.REACT_APP_CUSTOMERIO_SITE_ID;
    const formId = process.env.REACT_APP_CUSTOMERIO_FORM_ID;
    const successUrl = "https://bespokeaffiliates.com/thank-you";

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!siteId || !formId) {
            console.error('Missing environment variables');
            return;
        }

        setStatus('loading');

        try {
            const form = document.createElement('form');
            form.method = 'POST';
            form.action = `${formAction}?site_id=${siteId}&form_id=${formId}&success_url=${encodeURIComponent(successUrl)}`;

            const emailInput = document.createElement('input');
            emailInput.type = 'hidden';
            emailInput.name = 'email';
            emailInput.value = e.target.email.value;
            form.appendChild(emailInput);

            setStatus('success');

            setTimeout(() => {
                document.body.appendChild(form);
                form.submit();
                document.body.removeChild(form);

                setTimeout(() => {
                    setStatus('idle');
                    e.target.reset();
                }, 1000);
            }, 2000); // Reduced to 2 seconds to make the flow smoother

        } catch (error) {
            console.error('Subscription error:', error);
            setStatus('idle');
        }
    }

    const buttonText = {
        idle: 'Subscribe',
        loading: 'Subscribing...',
        success: 'Subscribed!'
    }[status];

    return (
        <form onSubmit={handleSubmit} className="flex items-center gap-2 mt-8 lg:mt-0">
            <input
                id="email_input"
                type="email"
                name="email"
                placeholder="Your Email Address"
                required
                disabled={status === 'loading' || status === 'success'}
                className="px-4 py-2 rounded-3xl bg-[#0C142C] border border-[#7F87F9] text-white placeholder-gray-400 focus:outline-none focus:border-blue-500 w-[200px]"
            />
            <button
                type="submit"
                disabled={status === 'loading' || status === 'success'}
                className={`bg-gradient-to-r from-indigo-900 to-indigo-400 hover:from-indigo-400 hover:to-indigo-900 text-white font-bold py-3 px-6 rounded-3xl transition duration-300 ease-in-out transform hover:scale-105 ${(status === 'loading' || status === 'success') ? 'opacity-50 cursor-not-allowed' : ''}`}
            >
                {buttonText}
            </button>
        </form>
    );
};

export default Subscription;