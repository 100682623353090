import React from "react";
import entry from "../assets/Section5/entry.svg"
import bronze from "../assets/Section5/bronze.svg"
import silver from "../assets/Section5/silver.svg"
import gold from "../assets/Section5/gold.svg"
import platinum from "../assets/Section5/platinum.svg"
import entryBar from "../assets/Section5/EntryBar.svg"
import bronzeBar from "../assets/Section5/BronzeBar.svg"
import silverBar from "../assets/Section5/silverBar.svg"
import goldBar from "../assets/Section5/goldBar.svg"
import platinumBar from "../assets/Section5/platinumBar.svg"
import commaDown from "../assets/Section5/commaDown.svg"
import commaUp from "../assets/Section5/commaUp.svg"
import bigComma from "../assets/Section5/bigCommaUp.svg"
import bigCommaDown from "../assets/Section5/bigCommaDown.svg"
import smallVector from "../assets/Section5/smallVector.svg"
import bigVector from "../assets/Section5/bigVector.svg"
import SlideAsAnything from "./Carousel";
import SlideAsAnythingg from "./CarouselTestemonial";

const Section5 = () => {

    let Heads = [
        {name: "TIER"},
        {name: "GGR RANGE IN $"},
        {name: "COMMISSION"},
    ]

    let Rows = [
        {name: "Entry", range: "0 - 10,000", commission: "25%"},
        {name: "Bronze", range: "10,000 - 50,000", commission: "30%"},
        {name: "Silver", range: "50,000 - 100,000", commission: "35%"},
        {name: "Gold", range: "100,000 - 500,000", commission: "40%"},
        {name: "Platinum", range: "500,000 +", commission: "45%"},
    ]

    return (
        <div className=" w-full  relative" >

            <div className="max-w-[1450px] flex flex-col mx-auto  items-center z-20 relative  " >

                <div className="text-center flex flex-col mt-16  px-5  " id="commission" >
                    <h2 className="text-4xl font-bold">COMMISSIONS</h2>
                    <p className="mt-8">Experience Exceptional Commissions and Bespoke Deals</p>
                    <div className="flex flex-row relative">
                        <img src={smallVector} alt="" className="w-[200px] hidden absolute xl:inline -left-44 -top-7"/>
                    <p className="mt-5 hidden lg:block">
                        We offer commission and bonus structures tailored to suit individual needs and circumstances. No negative carryover.
                    </p>
                    </div>
                </div>
                {/*
                <div className="font-bold text-[#819DC8] mt-20 hidden lg:block  ">
                    <table className="table-auto mb-10">
                        <thead>
                        <tr>
                            <th></th>
                            {Heads.map((head) => (
                                <th key={head.name} className="text-start px-6 py-4 ">
                                    {head.name}
                                </th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {Rows.map((row, index) => (
                            <tr key={row.name}>
                                <td className="flex justify-center mt-[14px]">
                                    {index === 0 && <img src={entry} alt="" className="w-[65px]"/>}
                                    {index === 1 && <img src={bronze} alt="" className="w-[65px]"/>}
                                    {index === 2 && <img src={silver} alt="" className="w-[65px]"/>}
                                    {index === 3 && <img src={gold} alt="" className="w-[65px]"/>}
                                    {index === 4 && <img src={platinum} alt="" className="w-[65px] "/>}
                                </td>
                                <td className="px-6 py-8">{row.name}</td>
                                <td className="px-6">{row.range}</td>
                                <td className="text-white px-6">{row.commission}</td>
                                <td className="pl-5">
                                    {index === 0 && <img src={entryBar} alt="" className="w-[350px]"/>}
                                    {index === 1 && <img src={bronzeBar} alt="" className="w-[350px]"/>}
                                    {index === 2 && <img src={silverBar} alt="" className="w-[350px]"/>}
                                    {index === 3 && <img src={goldBar} alt="" className="w-[350px]"/>}
                                    {index === 4 && <img src={platinumBar} alt="" className="w-[350px]"/>}
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <SlideAsAnything></SlideAsAnything>
               */}

                <div className="w-full mx-auto  flex flex-col justify-center mt-20 lg:mt-32 gap-5 lg:gap-0 " id="testimonials">
                    <div className="text-center">
                        <h2 className="text-4xl font-bold">TESTIMONIALS</h2>

                    </div>
                    <SlideAsAnythingg></SlideAsAnythingg>
                    <div className="  lg:flex-row justify-around items-center mt-32 px-3 gap-5 lg:gap-0 hidden lg:flex ">
                        <div className="flex flex-col items-center justify-center rounded-lg shadow-xl bg-gradient-to-r from-gray-900 to-blue-950 h-[260px]  lg:w-[360px] xl:w-[500px] lg:h-[235px]">
                            <img src={commaDown} alt="" className="w-[17.856px] mb-2"/>
                            <div className="flex flex-col items-start px-7">
                                <p className="italic"> The team behind Bespoke Affiliates is all about successful
                                    partnerships and their
                                    clients’ satisfaction. If you’re in search of a reliable affiliate program, this one
                                    is
                                    definitely worth looking into.
                                </p>
                                <h2 className="font-bold text-indigo-400 ">Casinoisy</h2>
                            </div>
                            <img src={commaUp} alt="" className="w-[17.856px] mt-2"/>
                        </div>
                        <div className="flex flex-col items-center justify-center rounded-lg shadow-2xl bg-gradient-to-r from-gray-900 to-blue-950 h-[260px] lg:w-[360px] xl:w-[500px] lg:h-[235px] opacity-90">
                            <img src={commaDown} alt="" className="w-[17.856px] mb-2"/>
                            <div className="flex flex-col items-start px-7">
                                <p className="italic"> Bespoke Affiliate has truly transformed our affiliate marketing
                                    strategy. Partnering  with them has been a game-changer. The seamless integration,
                                    top-notch marketing materials, and dedicated support have maximized our earning
                                    potential.
                                </p>
                                <a href="https://tether.bet/" target="_blank" ><h2 className="font-bold text-indigo-400">tether.bet</h2></a>
                            </div>
                            <img src={commaUp} alt="" className="w-[17.856px] mt-2"/>
                        </div>
                        <img src={bigComma} alt="" className="h-[160px] hidden md:block"/>
                    </div>
                    <div className="hidden lg:flex lg:flex-row justify-around items-center lg:mt-20 px-3 gap-5 lg:gap-0  ">
                        <img src={bigCommaDown} alt="" className="h-[160px] hidden md:block"/>
                        <div className="flex flex-col items-center justify-center rounded-lg shadow-2xl bg-gradient-to-r from-gray-900 to-blue-950 h-[260px]  lg:w-[360px] xl:w-[500px] lg:h-[235px]">
                            <img src={commaDown} alt="" className="w-[17.856px] mb-2"/>
                            <div className="flex flex-col items-start px-7">
                                <p className="italic"> Bespoke Affiliates is a new exciting affiliate program launched
                                    by a group of
                                    individuals who have proved to know this industry very well. This is a great
                                    product, with a strong team and innovative vision.
                                </p>
                                <h2 className="font-bold text-indigo-400">Casino Affiliate Programs</h2>
                            </div>
                            <img src={commaUp} alt="" className="w-[17.856px] mt-2"/>
                        </div>
                        <div className="flex flex-col items-center justify-center rounded-lg shadow-2xl bg-gradient-to-r from-gray-900 to-blue-950 h-[260px]  lg:w-[360px] xl:w-[500px] lg:h-[235px] opacity-90">
                            <img src={commaDown} alt="" className="w-[17.856px] mb-2"/>
                            <div className="flex flex-col items-start px-7">
                                <p className="italic"> The Bespoke Affiliate team really understand what affiliates want
                                    and need for
                                    successful partnerships. It's a pleasure working with them, and would highly
                                    recommend them to affiliates looking for a trust worthy programs.
                                </p>
                                <h2 className="font-bold text-indigo-400">TagMedia</h2>
                            </div>
                            <img src={commaUp} alt="" className="w-[17.856px] mt-2"/>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex flex-col items-center justify-center text-center mt-28 px-3 md:px-20 lg:px-0">

                       <h2 className="uppercase  text-2xl md:text-4xl font-bold mb-10 lg:mx-52  ">Join Bespoke Affiliates today and experience the power of a partnership that puts your
                           success first.</h2>
                        <p className="mb-14 lg:mx-32 "> Elevate your earnings, access top-tier brands, and enjoy the fastest payments
                            in the industry. Let's thrive together in the dynamic world of iGaming. Don't miss out on this exclusive opportunity to maximize your affiliate business. Sign up
                            today and unlock a world of high-converting opportunities, lightning-fast payments, and
                            personalized support. The time to thrive is now!
                        </p>
                        <a href="https://admin.bespokeaffiliates.com/partner/register" className="font-bold bg-gradient-to-r from-[#211D8A] to-indigo-400 rounded-3xl px-5 py-2 mt-6">Join Now for Maximum Earnings </a>

                    </div>
                </div>
            </div>
            <img src={bigVector} alt="" className="absolute right-0 top-[580px] hidden xl:inline z-10"/>
        </div>
    );
}

export default Section5;